import makeStyles from '@material-ui/styles/makeStyles'
import { spacing, colors } from 'tunstall-ui/core/variables'

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },

  timeStamp: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
  },

  dotContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    height: '49px', // uneven value to allow grey line to be perfectly centered
    width: '100%',
  },

  greyLine: {
    position: 'absolute',
    top: '50%',
    height: '1px',
    backgroundColor: colors.battleshipGreyT20,
    width: '100%',
  },

  dateNumber: {
    color: colors.battleshipGrey,
    paddingTop: spacing(5),
    letterSpacing: '0.3px',
  },

  lastDateNumber: {
    color: colors.battleshipGreyS10,
    fontWeight: 600,
    paddingTop: spacing(5),
  },
}))
