import makeStyles from '@material-ui/styles/makeStyles'
import { spacing, colors } from 'tunstall-ui/core/variables'

export const useStyles = makeStyles(() => ({
  timelineWrapper: {
    margin: `${spacing(4)} ${spacing(6)}`,
  },

  timelineTitle: {
    color: colors.battleshipGreyS5,
  },

  timeline: {
    margin: spacing(6),
  },

  checkInTime: {
    display: 'inline-flex',
    alignItems: 'center',
  },

  timeIcon: {
    marginLeft: spacing(2),
  },

  modal: {
    '& .MuiDialog-paper': {
      height: '100%',
      '& .MuiDialogTitle-root': {
        borderBottom: `1px solid ${colors.battleshipGreyT20}`,
      },
    },
  },
}))
