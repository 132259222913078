import React, { useMemo, useState, useCallback } from 'react'
import { CheckInDashboardDto, useTimeWindowGetDefault } from 'generated/api'
import { Typography, Button, Tooltip } from 'tunstall-ui/components'
import SetTimeWindowModal from 'components/SetTimeWindowModal/SetTimeWindowModal'
import clsx from 'clsx'
import { InfoIcon, FailIcon, SuccessIcon, MinusIcon } from 'tunstall-ui/icons'
import { useTranslation } from 'react-i18next'
import { colors } from 'tunstall-ui/core/variables'
import { useStyles } from './DashboardSummary.styles'

interface DashboardSummaryProps {
  dashboardCheckIns: CheckInDashboardDto | null
}

const emptyTimeWindow = {
  from: '00:00',
  to: '00:00',
}

const DashboardSummary = ({ dashboardCheckIns }: DashboardSummaryProps) => {
  const { data: defaultTimeWindow, refetch: refetchDefaultTimeWindow } = useTimeWindowGetDefault({})
  const classes = useStyles()
  const { t } = useTranslation()
  const [isDefaultTimeWindowModalOpen, setIsDefaultTimeWindowModalOpen] = useState(false)

  const handleCloseModal = useCallback(() => {
    setIsDefaultTimeWindowModalOpen(false)
  }, [setIsDefaultTimeWindowModalOpen])

  const handleOpenModal = useCallback(() => {
    setIsDefaultTimeWindowModalOpen(true)
  }, [setIsDefaultTimeWindowModalOpen])

  const handleDefaultTimeWindowUpdated = useCallback(() => {
    refetchDefaultTimeWindow()
    setIsDefaultTimeWindowModalOpen(false)
  }, [setIsDefaultTimeWindowModalOpen, refetchDefaultTimeWindow])

  const amountCheckedIn = useMemo(
    () => dashboardCheckIns?.checkIns.filter((checkIn) => checkIn.status === 'CheckedIn').length,
    [dashboardCheckIns?.checkIns]
  )

  const amountMissedCheckedIn = useMemo(
    () =>
      dashboardCheckIns?.checkIns.filter((checkIn) => checkIn.status === 'MissedCheckedIn').length,
    [dashboardCheckIns?.checkIns]
  )

  const amountAway = useMemo(
    () => dashboardCheckIns?.checkIns.filter((checkIn) => checkIn.status === 'IsAway').length,
    [dashboardCheckIns?.checkIns]
  )

  const amountCareRecipients = dashboardCheckIns?.checkIns?.length

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h2">
          {t('DashboardSummary.totalRecipients', 'Total care recipients: {{count}}', {
            count: amountCareRecipients ?? 0,
          })}
        </Typography>
      </div>
      <div className={classes.statusRow}>
        <div>
          <div className={classes.statusWrapper}>
            <div className={classes.status}>
              <span className={clsx(classes.icon, 'success')}>
                <SuccessIcon htmlColor={colors.white} width={16} height={16} />
              </span>
              <Typography variant="h3">
                <span className={classes.peopleCount}>{amountCheckedIn ?? 0}</span>
                {t('DashboardSummary.peopleCount.checkedin', {
                  count: amountCheckedIn,
                  defaultValue: 'Checked in',
                })}
              </Typography>
            </div>
            <div className={classes.status}>
              <span className={clsx(classes.icon, 'fail')}>
                <FailIcon htmlColor={colors.white} width={16} height={16} />
              </span>
              <Typography variant="h3">
                <span className={classes.peopleCount}>{amountMissedCheckedIn ?? 0}</span>
                {t('DashboardSummary.peopleCount.missed', {
                  count: amountMissedCheckedIn,
                  defaultValue: 'Missed check-ins',
                })}
              </Typography>
            </div>
            <div className={classes.status}>
              <span className={clsx(classes.icon, 'warning')}>
                <MinusIcon htmlColor={colors.white} width={16} height={16} />
              </span>
              <Typography variant="h3">
                <span className={classes.peopleCount}>{amountAway ?? 0}</span>
                {t('DashboardSummary.peopleCount.away', {
                  count: amountAway,
                  defaultValue: 'is away',
                })}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.times}>
            <div className={classes.timeTypes}>
              <Typography variant="h3" component="div">
                {t('DashboardSummary.defaultTime', 'Default time window')}
              </Typography>
              <div className={classes.timeButton}>
                <Button variant="text" onClick={handleOpenModal}>
                  {`${defaultTimeWindow?.from ?? `00:00`}-${defaultTimeWindow?.to ?? `00:00`}`}
                </Button>
                <SetTimeWindowModal
                  open={isDefaultTimeWindowModalOpen}
                  onUpdated={handleDefaultTimeWindowUpdated}
                  defaultTimeWindow={defaultTimeWindow ?? emptyTimeWindow}
                  onClose={handleCloseModal}
                />
              </div>
            </div>
            <div className={classes.timeWindows}>
              <div className={classes.averageTimeInfo}>
                <Typography variant="h3" component="div">
                  {t('DashboardSummary.averageTime', 'Average Time')}
                </Typography>
                <Tooltip
                  title={`${t(
                    'DashboardSummary.averageTimeInfo',
                    'Displays the average time for when the care recipients have reported'
                  )}`}
                >
                  <span data-testid="info-icon">
                    <InfoIcon
                      htmlColor={colors.azure5}
                      height={16}
                      width={16}
                      className={classes.infoIcon}
                    />
                  </span>
                </Tooltip>
              </div>
              <Typography variant="h3" component="div">
                ~ {dashboardCheckIns?.averageCheckInTime ?? ''}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardSummary
