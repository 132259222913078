import React, { useMemo, useState, useCallback } from 'react'
import { Modal, Button, Table, Divider, Typography, Tooltip } from 'tunstall-ui/components'
import Timeline from 'components/Timeline/Timeline'
import { TimeSpanIcon } from 'tunstall-ui/icons'
import {
  CheckInStatus,
  HistoryCheckInInfoDto,
  TimeWindowDto,
  useDashboardGetHistory,
} from 'generated/api'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'
import { useStyles } from './HistoryModal.styles'

const columnData = [
  {
    Header: i18n.t('HistoryModal.tableColumn.date', 'DATE'),
    accessor: 'date',
  },
  {
    Header: i18n.t('HistoryModal.tableColumn.timeCheckedIn', 'TIME CHECKED IN'),
    accessor: 'timeCheckedIn',
  },
  {
    Header: i18n.t('HistoryModal.tableColumn.action', 'ACTION'),
    accessor: 'action',
  },
]

const SHOW_AMOUNT_DAYS = 30

interface HistoryModalProps {
  onClose: () => void
  personId: string
}

const hasCheckInChanged = (prev: HistoryCheckInInfoDto, current: HistoryCheckInInfoDto) =>
  JSON.stringify(prev.checkInWindow) !== JSON.stringify(current.checkInWindow)

const HistoryModal = ({ onClose, personId }: HistoryModalProps) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { data: dashboardHistory } = useDashboardGetHistory({ personId })
  const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | undefined | null>()

  const checkInsOldestFirst = useMemo(
    () =>
      [...(dashboardHistory?.checkIns ?? [])].sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      ),
    [dashboardHistory?.checkIns]
  )

  const displayStatus = useCallback(
    (status: CheckInStatus, time: string | undefined | null, checkInWindow: TimeWindowDto) => {
      if (status === 'NotYetCheckedIn') {
        return <Typography variant="body2">-</Typography>
      }
      if (status === 'IsAway') {
        return (
          <Typography variant="body2" italic>
            {t('HistoryModal.status.away', 'Away')}
          </Typography>
        )
      }
      if (status === 'CheckedIn') {
        return (
          <div className={classes.checkInTime}>
            <Typography variant="body2">{time}</Typography>
            {/* TODO: fix in table. Wrapping with span is necessary, currently a bug */}
            <span>
              <Tooltip title={`${checkInWindow.from} - ${checkInWindow.to}`}>
                <span>
                  <TimeSpanIcon className={classes.timeIcon} width={14} height={14} />
                </span>
              </Tooltip>
            </span>
          </div>
        )
      }
      if (status === 'MissedCheckedIn') {
        return (
          <Typography variant="body2" italic>
            {t('HistoryModal.status.missedCheckedIn', 'Missed checked in')}
          </Typography>
        )
      }
      if (status === 'ServiceUnavailable') {
        return (
          <Typography variant="body2" italic>
            {t('HistoryModal.status.serviceUnavailable', 'Service Unavailable')}
          </Typography>
        )
      }
      return (
        <Typography variant="body2" italic>
          {status}
        </Typography>
      )
    },
    [classes.checkInTime, classes.timeIcon, t]
  )

  const rows = useMemo(
    () =>
      checkInsOldestFirst
        ?.map(({ date, status, time, checkInWindow }, index) => ({
          date,
          timeCheckedIn: displayStatus(status, time, checkInWindow),
          action:
            index - 1 >= 0 &&
            hasCheckInChanged(checkInsOldestFirst[index - 1], checkInsOldestFirst[index])
              ? `${t('HistoryModal.table.action.timeWindowChanged', 'Changed time to:')}  ${
                  checkInWindow.from
                } - ${checkInWindow.to}`
              : '',
        }))
        .slice(0, SHOW_AMOUNT_DAYS),
    [checkInsOldestFirst, t, displayStatus]
  )

  return (
    <Modal
      className={classes.modal}
      width="large"
      noPadding
      open={Boolean(personId)}
      title={dashboardHistory?.personName}
      actions={
        <Button variant="text" onClick={onClose}>
          {t('general.close', 'Close')}
        </Button>
      }
      onClose={onClose}
    >
      <div ref={setScrollContainer}>
        <div className={classes.timelineWrapper}>
          <div className={classes.timelineTitle}>
            {t('HistoryModal.timeline.title', 'Last 30 days')}
          </div>
          <Timeline
            className={classes.timeline}
            checkIns={checkInsOldestFirst.slice(0, SHOW_AMOUNT_DAYS)}
          />
        </div>
        <Divider />
        <Table
          title={t('HistoryModal.table.title', 'Check in history')}
          globalSearchFilterLabel={t('general.table.filter', 'Search')}
          columnClearFilterLabel={t('general.table.clear', 'Clear')}
          contextMenuLabel={t('general.table.filterBy', 'Filter by')}
          scrollContainer={scrollContainer}
          hideFilterRow
          columns={columnData}
          rows={rows ?? []}
        />
      </div>
    </Modal>
  )
}

export default React.memo(HistoryModal)
