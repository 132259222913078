import format from 'date-fns/format'
import { toNumber } from 'lodash'

// converts from hh:mm to { hour, minute }
export const convertToHourAndMinute = (time: string) => {
  if (!/^\d{2}:\d{2}$/.test(time)) {
    throw Error(`${time}, Wrong format, it should be in hh:mm`)
  }
  const [hour, minute] = time.split(':').map(toNumber)

  if (hour > 23 || minute > 59) {
    throw Error(`${time}, can't be greater than 23:59`)
  }
  return { hour, minute }
}

export const convertTo12h = (twentyFourHourTime: string) => {
  const { hour, minute } = convertToHourAndMinute(twentyFourHourTime)
  const date = new Date(0, 0, 0, hour, minute)
  return format(date, 'hh:mm a')
}

// Convert from 'hh:mm' to number (0-24)
export const convertTimeToNumber = (time: string) => {
  const { hour, minute } = convertToHourAndMinute(time)
  return hour + minute / 60
}

// Convert from number (0-24) to 'HH:mm' (24h clock)
export const convertNumberToTime = (number: number) => {
  if (number > 24 || number < 0) {
    throw Error(`${number}, has to be between 0 and 24`)
  }
  const hour = Math.floor(number)
  const minute = (number - hour) * 60
  const date = new Date(0, 0, 0, hour, minute)

  return format(date, 'HH:mm')
}
