import makeStyles from '@material-ui/styles/makeStyles'
import { colors, spacing } from 'tunstall-ui/core/variables'
import { createMuiTheme } from '@material-ui/core'

export const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersCalendarHeader: {
      dayLabel: {
        fontWeight: 600,
        color: colors.battleshipGreyS10,
        fontSize: '13px',
      },
      iconButton: {
        color: colors.battleshipGreyS10,
        '&:hover': {
          backgroundColor: colors.hover,
        },
      },
    },
    MuiPickersDay: {
      day: {
        color: colors.battleshipGreyS20,
        '&:hover': {
          backgroundColor: colors.hover,
        },
      },
      daySelected: {
        backgroundColor: colors.azure0,
        '&:hover': {
          backgroundColor: colors.azure0,
        },
      },
      dayDisabled: {
        color: '#e5e8ed',
      },
      current: {
        color: colors.azure0,
      },
    },
  },
})

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing(1),
  },

  leftArrow: {
    cursor: 'pointer',
  },

  rightArrow: {
    cursor: 'pointer',
    transform: 'rotate(180deg)',
  },

  label: {
    fontFamily: 'Source Sans Pro',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: '-0.25px',
    color: colors.battleshipGreyS20,
    margin: `${spacing(1)} ${spacing(4)}`,
  },
}))
