import React from 'react'
import clsx from 'clsx'
import { Typography } from 'tunstall-ui/components'
import { format } from 'date-fns'
import { HistoryCheckInInfoDto } from 'generated/api'
import isToday from 'date-fns/isToday'
import { useStyles } from './Timeline.styles'
import Dot from './Dot'

const checkToday = (date: Date) => isToday(date)

interface TimelineProps {
  checkIns?: HistoryCheckInInfoDto[]
  className?: string
}

const Timeline = ({ checkIns, className }: TimelineProps) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.container, className)}>
      {checkIns?.map((person) => (
        <div className={classes.timeStamp} key={`${person.date}_${person.time}`}>
          <div className={classes.dotContainer}>
            <div className={classes.greyLine} />
            <Dot type={person.status} />
          </div>
          <Typography
            className={clsx(classes.dateNumber, {
              [classes.lastDateNumber]: checkToday(new Date(person.date)),
            })}
          >
            {format(new Date(person.date), 'dd')}
          </Typography>
        </div>
      ))}
    </div>
  )
}

export default Timeline
