import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { Card, Link, Table, IconButton, Typography, Tooltip } from 'tunstall-ui/components'
import { ColumnType } from 'tunstall-ui/components/Table/HeaderRow/ColumnHeader/ColumnHeader'
import { colors } from 'tunstall-ui/core/variables'
import {
  TimeSpanIcon,
  WarningOutlinedIcon,
  StatisticsOutlinedIcon,
  FailIcon,
  SuccessIcon,
  MinusIcon,
} from 'tunstall-ui/icons'
import i18n from 'i18n'
import format from 'date-fns/format'
import { useTranslation } from 'react-i18next'
import DashboardSummary from 'components/DashboardSummary/DashboardSummary'
import SetTimeWindowModal from 'components/SetTimeWindowModal/SetTimeWindowModal'
import {
  TimeWindowDto,
  useDashboardGetCheckIns,
  useTimeWindowGetDefault,
  CheckInStatus,
} from 'generated/api'
import { urls } from 'config/constants'
import HistoryModal from 'components/HistoryModal/HistoryModal'
import SimpleDatePicker from 'components/SimpleDatePicker/SimpleDatePicker'
import useGetInitialDate from 'hooks/useGetInitialDate'
import { orderBy } from 'lodash'
import { useStyles } from './HomePage.styles'

let timerId = -1
const columnData = [
  {
    Header: i18n.t('HomePage.tableColumn.Facilities', 'Facilities'),
    accessor: 'facility',
    type: ColumnType.Filter,
  },
  {
    Header: i18n.t('HomePage.tableColumn.apartment', 'Apartment'),
    accessor: 'apartment',
  },
  {
    Header: i18n.t('HomePage.tableColumn.personName', 'Care recipient'),
    accessor: 'personName',
  },
  {
    Header: i18n.t('HomePage.tableColumn.checkInWindow', 'Check in window'),
    accessor: 'checkInWindow',
  },
  {
    Header: i18n.t('HomePage.tableColumn.time', 'Time'),
    accessor: 'time',
  },
  {
    Header: i18n.t('HomePage.tableColumn.status', 'Status'),
    accessor: 'status',
    type: ColumnType.SingleFilter,
  },
  {
    Header: i18n.t('HomePage.tableColumn.history', 'History'),
    accessor: 'deviation',
  },
]

const formatSelectedDate = (chosenDate: Date) => format(chosenDate, 'yyyy-MM-dd')

const emptyTimeWindow = {
  from: '00:00',
  to: '00:00',
}

const isAwayBgColor = 'rgba(232, 251, 226, 0.3)'

const statusToIconMap = {
  MissedCheckedIn: <FailIcon width={16} height={16} htmlColor={colors.accCoral} />,
  CheckedIn: <SuccessIcon width={16} height={16} htmlColor={colors.accGreenS10} />,
  IsAway: <MinusIcon width={16} height={16} htmlColor={colors.battleshipGreyT5} />,
  NotYetCheckedIn: null,
  None: null,
  ServiceUnavailable: null,
}

interface PersonForTimeWindow {
  personId: string
  timeWindow: TimeWindowDto
}

const HomePage = () => {
  const { data: defaultTimeWindow } = useTimeWindowGetDefault({})
  const { t } = useTranslation()
  const initialDate = useGetInitialDate()
  const [date, setDate] = useState(initialDate)
  const { data: dashboardCheckIns, refetch: refetchCheckIns } = useDashboardGetCheckIns({
    date: formatSelectedDate(date),
  })
  const [personIdForHistory, setPersonIdForHistory] = useState('')
  const [personForTimeWindow, setPersonForTimeWindow] = useState<PersonForTimeWindow | null>(null)
  const [animateTimeChange, setAnimateTimeChange] = useState('')
  const classes = useStyles()

  // Poll checkIns every minute
  useEffect(() => {
    if (dashboardCheckIns?.checkIns) {
      timerId = window.setTimeout(() => {
        refetchCheckIns({
          pathParams: {
            date: formatSelectedDate(date),
          },
        })
      }, 60 * 1000)
    }
    return () => window.clearTimeout(timerId)
  }, [dashboardCheckIns, refetchCheckIns, date])

  const onCloseHistoryModal = useCallback(() => {
    setPersonIdForHistory('')
  }, [setPersonIdForHistory])

  const openTimeWindowModal = useCallback(
    (personId: string) => {
      const timeWindow =
        dashboardCheckIns?.checkIns.find((c) => c.personId === personId)?.checkInWindow ??
        emptyTimeWindow
      setPersonForTimeWindow({ personId, timeWindow })
    },
    [setPersonForTimeWindow, dashboardCheckIns]
  )

  const openPersonProfileinTES = (userIDinTES: string) => {
    const newWindow = window.open(
      `${urls.tesWebUrl}/person.aspx?personid=${userIDinTES}`,
      '_blank',
      'noopener,noreferrer'
    )
    if (newWindow) newWindow.opener = null
  }

  const onCloseTimeWindowModal = useCallback(() => {
    setPersonForTimeWindow(null)
  }, [setPersonForTimeWindow])

  const handleSpecificTimeWindowUpdated = useCallback(
    (personId) => {
      onCloseTimeWindowModal()
      setAnimateTimeChange(personId)
      setTimeout(() => {
        setAnimateTimeChange('')
      }, 1000)
      refetchCheckIns({
        pathParams: {
          date: formatSelectedDate(date),
        },
      })
    },
    [onCloseTimeWindowModal, date, refetchCheckIns, setAnimateTimeChange]
  )

  const renderStatus = (status: CheckInStatus) => statusToIconMap[status] ?? ''

  // TODO: remove 'desc' when table fixed. Used due to the table auto reversing the array.
  const sortedVisits = useMemo(
    () =>
      orderBy(
        dashboardCheckIns?.checkIns,
        ['facility', 'apartment', 'personName'],
        ['desc', 'desc', 'desc']
      ),
    [dashboardCheckIns]
  )

  const visitRows = useMemo(
    () =>
      sortedVisits?.map(
        ({
          apartment,
          checkInWindow,
          deviation,
          facility,
          isDefaultCheckInWindow,
          personId,
          personName,
          status,
          time,
        }) => ({
          facility:
            status === 'IsAway' ? (
              <Typography variant="body2" color={colors.battleshipGreyT5}>
                {facility}
              </Typography>
            ) : (
              facility
            ),
          apartment:
            status === 'IsAway' ? (
              <Typography variant="body2" color={colors.battleshipGreyT5}>
                {apartment}
              </Typography>
            ) : (
              apartment
            ),
          // Added onClick handler to navigate to person profile in TES. The table doesnt allow click from just href alone.
          personName: (
            <Link
              component="a"
              href={`${urls.tesWebUrl}/person.aspx?personid=${personId}`}
              onClick={() => openPersonProfileinTES(personId)}
            >
              {personName}
            </Link>
          ),
          checkInWindow: (
            <span className={classes.timeSpan}>
              {status === 'IsAway' ? (
                <Typography variant="body2" color={colors.battleshipGreyT5}>
                  {t('HomePage.table.row.checkInWindow', 'Away')}
                </Typography>
              ) : (
                `${checkInWindow.from}-${checkInWindow.to} `
              )}
              {!isDefaultCheckInWindow && status !== 'IsAway' ? (
                <span>
                  {/* TODO: fix in table. Wrapping with span is necessary, currently a bug */}
                  <Tooltip
                    title={`${t(
                      'HomePage.table.row.checkInWindowIcon',
                      'Individual check in  window'
                    )}`}
                  >
                    <span>
                      <TimeSpanIcon className={classes.timeSpanIcon} width={16} height={16} />
                    </span>
                  </Tooltip>
                </span>
              ) : (
                ''
              )}
            </span>
          ),
          time,
          status: renderStatus(status),
          deviation: (
            // TODO: Remove div wrapper. Bug in table removes outer element.
            <div>
              {deviation === 'None' ? (
                <IconButton
                  size={32}
                  bgColor="transparent"
                  contentColor="azure0"
                  shadow={false}
                  onClick={() => setPersonIdForHistory(personId)}
                >
                  <StatisticsOutlinedIcon />
                </IconButton>
              ) : (
                <IconButton
                  size={32}
                  bgColor="transparent"
                  contentColor="accCoral"
                  shadow={false}
                  onClick={() => setPersonIdForHistory(personId)}
                >
                  <WarningOutlinedIcon />
                </IconButton>
              )}
            </div>
          ),
          hoverMenuItems: [
            {
              label: t('HomePage.table.row.editSpecificTime', 'Edit specific time'),
              onClick: () => openTimeWindowModal(personId),
            },
          ],
          rowColor: personId === animateTimeChange ? isAwayBgColor : colors.white,
        })
      ),
    [
      setPersonIdForHistory,
      openTimeWindowModal,
      animateTimeChange,
      sortedVisits,
      t,
      classes.timeSpan,
      classes.timeSpanIcon,
    ]
  )

  return (
    <Card
      className={classes.card}
      title={
        <div className={classes.cardTitle}>
          <Typography variant="h2" className={classes.title}>
            {t('HomePage.title.imok', "I'm OK")}
          </Typography>
          <div className={classes.datePickerWrapper}>
            <SimpleDatePicker selectedDate={date} onDateChange={setDate} />
          </div>
        </div>
      }
    >
      <DashboardSummary dashboardCheckIns={dashboardCheckIns} />
      <Table
        globalSearchFilterLabel={t('general.table.filter', 'Search')}
        columnClearFilterLabel={t('general.table.clear', 'Clear')}
        contextMenuLabel={t('general.table.filterBy', 'Filter by')}
        hideTitleRow
        columns={columnData}
        rows={visitRows ?? []}
      />
      {personIdForHistory && (
        <HistoryModal personId={personIdForHistory} onClose={onCloseHistoryModal} />
      )}
      {personForTimeWindow && (
        <SetTimeWindowModal
          personId={personForTimeWindow.personId}
          open
          defaultTimeWindow={defaultTimeWindow ?? emptyTimeWindow}
          specificTimeWindow={personForTimeWindow.timeWindow}
          onClose={onCloseTimeWindowModal}
          onUpdated={handleSpecificTimeWindowUpdated}
        />
      )}
    </Card>
  )
}
export default HomePage
