import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TimeWindowDto, useTimeWindowSetDefault, useTimeWindowSetOverride } from 'generated/api'
import { Modal, Button, Slider } from 'tunstall-ui/components'
import { convertNumberToTime, convertTimeToNumber, convertTo12h } from 'utils/timeHelpers'
import { useStyles } from './SetTimeWindowModal.styles'

interface SetTimeWindowModalProps {
  onClose?: () => void
  onUpdated?: (personId: string) => void
  open: boolean
  defaultTimeWindow: TimeWindowDto
  // start - props for edit specific time window
  personId?: string
  specificTimeWindow?: TimeWindowDto
  // end - props for edit specific time window
}

const SetTimeWindowModal = ({
  onClose,
  open,
  onUpdated,
  defaultTimeWindow,
  specificTimeWindow,
  personId = '',
}: SetTimeWindowModalProps) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { mutate: setDefaultTimeWindow, loading: isSetDefaultLoading } = useTimeWindowSetDefault({})
  const { mutate: setSpecificTimeWindow, loading: isSetSpecificLoading } = useTimeWindowSetOverride(
    { personId }
  )
  const [values, setValues] = useState([0, 0])

  const isSettingSpecificTimeWindow = Boolean(personId) && Boolean(specificTimeWindow)
  const isLoading = isSetDefaultLoading || isSetSpecificLoading

  useEffect(() => {
    if (open) {
      const timeWindow = isSettingSpecificTimeWindow ? specificTimeWindow : defaultTimeWindow
      const sliderValues = [timeWindow!.from, timeWindow!.to].map(convertTimeToNumber)
      setValues(sliderValues)
    }
    // The dependency on "open" will cause it reset to default each time we open
  }, [defaultTimeWindow, specificTimeWindow, isSettingSpecificTimeWindow, open])

  const handleSliderChange = (event: React.ChangeEvent<unknown>, value: number | number[]) => {
    setValues(value as number[])
  }

  const sliderLabel = useMemo(() => {
    const [timeFrom, timeTo] = values.map(convertNumberToTime).map(convertTo12h)
    return `${timeFrom}-${timeTo}`
  }, [values])

  const handleSave = useCallback(async () => {
    const [from, to] = values.map(convertNumberToTime)

    const setTimeWindow = isSettingSpecificTimeWindow ? setSpecificTimeWindow : setDefaultTimeWindow

    const setDefaultTimeWindowRes = await setTimeWindow({
      from,
      to,
      // TODO: handle error (waiting for UX)
    }).catch(() => false)

    if (setDefaultTimeWindowRes) {
      onUpdated?.(personId)
    }
  }, [
    setDefaultTimeWindow,
    setSpecificTimeWindow,
    values,
    onUpdated,
    isSettingSpecificTimeWindow,
    personId,
  ])

  const handleResetToDefault = useCallback(() => {
    const { from, to } = defaultTimeWindow
    const sliderValues = [from, to].map(convertTimeToNumber)
    setValues(sliderValues)
  }, [defaultTimeWindow, setValues])

  const title = isSettingSpecificTimeWindow
    ? t('modal.setTimeWindow.specific.title', 'Set time specific time window')
    : t('modal.setTimeWindow.default.title', 'Set time window')

  const description = isSettingSpecificTimeWindow
    ? t(
        'modal.setTimeWindow.specific.description',
        'Select a time limit for when it’s OK to report'
      )
    : t('modal.setTimeWindow.default.description', 'Select a time limit for when it’s OK to report')

  return (
    <Modal
      width="medium"
      open={open}
      title={title}
      actions={
        <div className={classes.modalButtons}>
          {isSettingSpecificTimeWindow && (
            <Button
              className={classes.resetButton}
              variant="text"
              onClick={handleResetToDefault}
              disabled={isLoading}
            >
              {t('modal.setDefaultTimeWindow.resetToDefault', 'Reset to default')}
            </Button>
          )}
          <Button variant="text" color="battleshipGrey" onClick={onClose}>
            {t('modal.setDefaultTimeWindow.close', 'Decline')}
          </Button>
          <Button variant="text" onClick={handleSave} disabled={isLoading}>
            {t('modal.setDefaultTimeWindow.save', 'Save')}
          </Button>
        </div>
      }
      onClose={onClose}
    >
      <div className={classes.text}>{description}</div>
      <Slider
        disabled={isLoading}
        label={sliderLabel}
        value={values}
        onChange={handleSliderChange}
        step={0.25}
        min={0}
        max={23.99}
      />
    </Modal>
  )
}

export default SetTimeWindowModal
