/* Generated by restful-react */

import React from 'react'
import {
  Get,
  GetProps,
  useGet,
  UseGetProps,
  Mutate,
  MutateProps,
  useMutate,
  UseMutateProps,
} from 'restful-react'
export const SPEC_VERSION = '1.0'
export interface TimeWindowDto {
  from: string
  to: string
}

export interface TimeWindowsDto {
  default: TimeWindowDto
  overrides: {
    [key: string]: TimeWindowDto
  }
}

export interface ProblemDetails {
  [key: string]: {}
}

export interface CheckInPersonDto {
  timestampUtc: string
}

export type CurrentStatus =
  | 'BeforeTimeWindow'
  | 'CanCheckIn'
  | 'HasCheckedIn'
  | 'MissedCheckedIn'
  | 'IsAway'

export interface PersonStatusDto {
  personId: string
  timeWindow: TimeWindowDto
  status: CurrentStatus
}

export interface AwayStatusDto {
  isAway: boolean
}

export type CheckInDeviation = 'None' | 'MultipleMisses'

export type CheckInStatus =
  | 'None'
  | 'MissedCheckedIn'
  | 'CheckedIn'
  | 'IsAway'
  | 'ServiceUnavailable'
  | 'NotYetCheckedIn'

export interface DashboardCheckInInfoDto {
  personId: string
  personName: string
  facility: string
  apartment: string
  deviation: CheckInDeviation
  status: CheckInStatus
  time?: string | null
  checkInWindow: TimeWindowDto
  isDefaultCheckInWindow: boolean
}

export interface CheckInDashboardDto {
  date: string
  averageCheckInTime?: string | null
  checkIns: DashboardCheckInInfoDto[]
}

export interface HistoryCheckInInfoDto {
  date: string
  status: CheckInStatus
  time?: string | null
  checkInWindow: TimeWindowDto
  isDefaultCheckInWindow: boolean
}

export interface CheckInHistoryDto {
  personId: string
  personName: string
  checkIns: HistoryCheckInInfoDto[]
}

export interface ServiceHealthStatusDto {
  dependencies: {
    [key: string]: number
  }
}

export interface SetActivityMonitoringTenantDto {
  tenantCode: string
  timeZone: string
}

export interface ActivityMonitoringTenantDto {
  timeZone: string
  tenantCode: string
  deactivateAtUtc?: string | null
}

export type CheckInGetTimeWindowDefinitionsProps = Omit<
  GetProps<TimeWindowsDto, ProblemDetails, void, void>,
  'path'
>

export const CheckInGetTimeWindowDefinitions = (props: CheckInGetTimeWindowDefinitionsProps) => (
  <Get<TimeWindowsDto, ProblemDetails, void, void>
    path={`/activitymonitoring/api/v1/checkin/timewindows`}
    {...props}
  />
)

export type UseCheckInGetTimeWindowDefinitionsProps = Omit<
  UseGetProps<TimeWindowsDto, ProblemDetails, void, void>,
  'path'
>

export const useCheckInGetTimeWindowDefinitions = (
  props: UseCheckInGetTimeWindowDefinitionsProps
) =>
  useGet<TimeWindowsDto, ProblemDetails, void, void>(
    `/activitymonitoring/api/v1/checkin/timewindows`,
    props
  )

export interface CheckInCheckInPersonPathParams {
  personId: string
}

export type CheckInCheckInPersonProps = Omit<
  MutateProps<void, ProblemDetails, void, CheckInPersonDto, CheckInCheckInPersonPathParams>,
  'path' | 'verb'
> &
  CheckInCheckInPersonPathParams

export const CheckInCheckInPerson = ({ personId, ...props }: CheckInCheckInPersonProps) => (
  <Mutate<void, ProblemDetails, void, CheckInPersonDto, CheckInCheckInPersonPathParams>
    verb="POST"
    path={`/activitymonitoring/api/v1/checkin/${personId}`}
    {...props}
  />
)

export type UseCheckInCheckInPersonProps = Omit<
  UseMutateProps<void, ProblemDetails, void, CheckInPersonDto, CheckInCheckInPersonPathParams>,
  'path' | 'verb'
> &
  CheckInCheckInPersonPathParams

export const useCheckInCheckInPerson = ({ personId, ...props }: UseCheckInCheckInPersonProps) =>
  useMutate<void, ProblemDetails, void, CheckInPersonDto, CheckInCheckInPersonPathParams>(
    'POST',
    (paramsInPath: CheckInCheckInPersonPathParams) =>
      `/activitymonitoring/api/v1/checkin/${paramsInPath.personId}`,
    { pathParams: { personId }, ...props }
  )

export interface CheckInGetPersonStatusPathParams {
  personId: string
}

export type CheckInGetPersonStatusProps = Omit<
  GetProps<PersonStatusDto, ProblemDetails, void, CheckInGetPersonStatusPathParams>,
  'path'
> &
  CheckInGetPersonStatusPathParams

export const CheckInGetPersonStatus = ({ personId, ...props }: CheckInGetPersonStatusProps) => (
  <Get<PersonStatusDto, ProblemDetails, void, CheckInGetPersonStatusPathParams>
    path={`/activitymonitoring/api/v1/checkin/${personId}`}
    {...props}
  />
)

export type UseCheckInGetPersonStatusProps = Omit<
  UseGetProps<PersonStatusDto, ProblemDetails, void, CheckInGetPersonStatusPathParams>,
  'path'
> &
  CheckInGetPersonStatusPathParams

export const useCheckInGetPersonStatus = ({ personId, ...props }: UseCheckInGetPersonStatusProps) =>
  useGet<PersonStatusDto, ProblemDetails, void, CheckInGetPersonStatusPathParams>(
    (paramsInPath: CheckInGetPersonStatusPathParams) =>
      `/activitymonitoring/api/v1/checkin/${paramsInPath.personId}`,
    { pathParams: { personId }, ...props }
  )

export interface CheckInSetPersonAwayStatusPathParams {
  personId: string
}

export type CheckInSetPersonAwayStatusProps = Omit<
  MutateProps<void, ProblemDetails, void, AwayStatusDto, CheckInSetPersonAwayStatusPathParams>,
  'path' | 'verb'
> &
  CheckInSetPersonAwayStatusPathParams

export const CheckInSetPersonAwayStatus = ({
  personId,
  ...props
}: CheckInSetPersonAwayStatusProps) => (
  <Mutate<void, ProblemDetails, void, AwayStatusDto, CheckInSetPersonAwayStatusPathParams>
    verb="PUT"
    path={`/activitymonitoring/api/v1/checkin/${personId}/away`}
    {...props}
  />
)

export type UseCheckInSetPersonAwayStatusProps = Omit<
  UseMutateProps<void, ProblemDetails, void, AwayStatusDto, CheckInSetPersonAwayStatusPathParams>,
  'path' | 'verb'
> &
  CheckInSetPersonAwayStatusPathParams

export const useCheckInSetPersonAwayStatus = ({
  personId,
  ...props
}: UseCheckInSetPersonAwayStatusProps) =>
  useMutate<void, ProblemDetails, void, AwayStatusDto, CheckInSetPersonAwayStatusPathParams>(
    'PUT',
    (paramsInPath: CheckInSetPersonAwayStatusPathParams) =>
      `/activitymonitoring/api/v1/checkin/${paramsInPath.personId}/away`,
    { pathParams: { personId }, ...props }
  )

export interface DashboardGetCheckInsPathParams {
  date: string
}

export type DashboardGetCheckInsProps = Omit<
  GetProps<CheckInDashboardDto, ProblemDetails, void, DashboardGetCheckInsPathParams>,
  'path'
> &
  DashboardGetCheckInsPathParams

export const DashboardGetCheckIns = ({ date, ...props }: DashboardGetCheckInsProps) => (
  <Get<CheckInDashboardDto, ProblemDetails, void, DashboardGetCheckInsPathParams>
    path={`/activitymonitoring/api/v1/dashboard/${date}`}
    {...props}
  />
)

export type UseDashboardGetCheckInsProps = Omit<
  UseGetProps<CheckInDashboardDto, ProblemDetails, void, DashboardGetCheckInsPathParams>,
  'path'
> &
  DashboardGetCheckInsPathParams

export const useDashboardGetCheckIns = ({ date, ...props }: UseDashboardGetCheckInsProps) =>
  useGet<CheckInDashboardDto, ProblemDetails, void, DashboardGetCheckInsPathParams>(
    (paramsInPath: DashboardGetCheckInsPathParams) =>
      `/activitymonitoring/api/v1/dashboard/${paramsInPath.date}`,
    { pathParams: { date }, ...props }
  )

export interface DashboardGetHistoryPathParams {
  personId: string
}

export type DashboardGetHistoryProps = Omit<
  GetProps<CheckInHistoryDto, ProblemDetails, void, DashboardGetHistoryPathParams>,
  'path'
> &
  DashboardGetHistoryPathParams

export const DashboardGetHistory = ({ personId, ...props }: DashboardGetHistoryProps) => (
  <Get<CheckInHistoryDto, ProblemDetails, void, DashboardGetHistoryPathParams>
    path={`/activitymonitoring/api/v1/dashboard/history/${personId}`}
    {...props}
  />
)

export type UseDashboardGetHistoryProps = Omit<
  UseGetProps<CheckInHistoryDto, ProblemDetails, void, DashboardGetHistoryPathParams>,
  'path'
> &
  DashboardGetHistoryPathParams

export const useDashboardGetHistory = ({ personId, ...props }: UseDashboardGetHistoryProps) =>
  useGet<CheckInHistoryDto, ProblemDetails, void, DashboardGetHistoryPathParams>(
    (paramsInPath: DashboardGetHistoryPathParams) =>
      `/activitymonitoring/api/v1/dashboard/history/${paramsInPath.personId}`,
    { pathParams: { personId }, ...props }
  )

export type HealthGetServiceStatusProps = Omit<
  GetProps<ServiceHealthStatusDto, ServiceHealthStatusDto, void, void>,
  'path'
>

export const HealthGetServiceStatus = (props: HealthGetServiceStatusProps) => (
  <Get<ServiceHealthStatusDto, ServiceHealthStatusDto, void, void>
    path={`/activitymonitoring/api/v1/health/status`}
    {...props}
  />
)

export type UseHealthGetServiceStatusProps = Omit<
  UseGetProps<ServiceHealthStatusDto, ServiceHealthStatusDto, void, void>,
  'path'
>

export const useHealthGetServiceStatus = (props: UseHealthGetServiceStatusProps) =>
  useGet<ServiceHealthStatusDto, ServiceHealthStatusDto, void, void>(
    `/activitymonitoring/api/v1/health/status`,
    props
  )

export type HealthGetServiceLivenessProps = Omit<GetProps<void, void, void, void>, 'path'>

export const HealthGetServiceLiveness = (props: HealthGetServiceLivenessProps) => (
  <Get<void, void, void, void> path={`/activitymonitoring/api/v1/health/liveness`} {...props} />
)

export type UseHealthGetServiceLivenessProps = Omit<UseGetProps<void, void, void, void>, 'path'>

export const useHealthGetServiceLiveness = (props: UseHealthGetServiceLivenessProps) =>
  useGet<void, void, void, void>(`/activitymonitoring/api/v1/health/liveness`, props)

export type HealthGetServiceReadinessProps = Omit<GetProps<void, void, void, void>, 'path'>

export const HealthGetServiceReadiness = (props: HealthGetServiceReadinessProps) => (
  <Get<void, void, void, void> path={`/activitymonitoring/api/v1/health/readiness`} {...props} />
)

export type UseHealthGetServiceReadinessProps = Omit<UseGetProps<void, void, void, void>, 'path'>

export const useHealthGetServiceReadiness = (props: UseHealthGetServiceReadinessProps) =>
  useGet<void, void, void, void>(`/activitymonitoring/api/v1/health/readiness`, props)

export type TenantAddProps = Omit<
  MutateProps<void, ProblemDetails, void, SetActivityMonitoringTenantDto, void>,
  'path' | 'verb'
>

export const TenantAdd = (props: TenantAddProps) => (
  <Mutate<void, ProblemDetails, void, SetActivityMonitoringTenantDto, void>
    verb="POST"
    path={`/activitymonitoring/api/v1/tenant`}
    {...props}
  />
)

export type UseTenantAddProps = Omit<
  UseMutateProps<void, ProblemDetails, void, SetActivityMonitoringTenantDto, void>,
  'path' | 'verb'
>

export const useTenantAdd = (props: UseTenantAddProps) =>
  useMutate<void, ProblemDetails, void, SetActivityMonitoringTenantDto, void>(
    'POST',
    `/activitymonitoring/api/v1/tenant`,
    props
  )

export type TenantUpdateProps = Omit<
  MutateProps<void, ProblemDetails, void, SetActivityMonitoringTenantDto, void>,
  'path' | 'verb'
>

export const TenantUpdate = (props: TenantUpdateProps) => (
  <Mutate<void, ProblemDetails, void, SetActivityMonitoringTenantDto, void>
    verb="PUT"
    path={`/activitymonitoring/api/v1/tenant`}
    {...props}
  />
)

export type UseTenantUpdateProps = Omit<
  UseMutateProps<void, ProblemDetails, void, SetActivityMonitoringTenantDto, void>,
  'path' | 'verb'
>

export const useTenantUpdate = (props: UseTenantUpdateProps) =>
  useMutate<void, ProblemDetails, void, SetActivityMonitoringTenantDto, void>(
    'PUT',
    `/activitymonitoring/api/v1/tenant`,
    props
  )

export type TenantDeleteProps = Omit<
  MutateProps<void, ProblemDetails, void, string, void>,
  'path' | 'verb'
>

export const TenantDelete = (props: TenantDeleteProps) => (
  <Mutate<void, ProblemDetails, void, string, void>
    verb="DELETE"
    path={`/activitymonitoring/api/v1/tenant`}
    {...props}
  />
)

export type UseTenantDeleteProps = Omit<
  UseMutateProps<void, ProblemDetails, void, string, void>,
  'path' | 'verb'
>

export const useTenantDelete = (props: UseTenantDeleteProps) =>
  useMutate<void, ProblemDetails, void, string, void>(
    'DELETE',
    `/activitymonitoring/api/v1/tenant`,
    { ...props }
  )

export interface TenantDeactivatePathParams {
  tenantCode: string
}

export type TenantDeactivateProps = Omit<
  MutateProps<void, ProblemDetails, void, void, TenantDeactivatePathParams>,
  'path' | 'verb'
> &
  TenantDeactivatePathParams

export const TenantDeactivate = ({ tenantCode, ...props }: TenantDeactivateProps) => (
  <Mutate<void, ProblemDetails, void, void, TenantDeactivatePathParams>
    verb="PUT"
    path={`/activitymonitoring/api/v1/tenant/${tenantCode}/deactivate`}
    {...props}
  />
)

export type UseTenantDeactivateProps = Omit<
  UseMutateProps<void, ProblemDetails, void, void, TenantDeactivatePathParams>,
  'path' | 'verb'
> &
  TenantDeactivatePathParams

export const useTenantDeactivate = ({ tenantCode, ...props }: UseTenantDeactivateProps) =>
  useMutate<void, ProblemDetails, void, void, TenantDeactivatePathParams>(
    'PUT',
    (paramsInPath: TenantDeactivatePathParams) =>
      `/activitymonitoring/api/v1/tenant/${paramsInPath.tenantCode}/deactivate`,
    { pathParams: { tenantCode }, ...props }
  )

export interface TenantReactivatePathParams {
  tenantCode: string
}

export type TenantReactivateProps = Omit<
  MutateProps<void, ProblemDetails, void, void, TenantReactivatePathParams>,
  'path' | 'verb'
> &
  TenantReactivatePathParams

export const TenantReactivate = ({ tenantCode, ...props }: TenantReactivateProps) => (
  <Mutate<void, ProblemDetails, void, void, TenantReactivatePathParams>
    verb="PUT"
    path={`/activitymonitoring/api/v1/tenant/${tenantCode}/reactivate`}
    {...props}
  />
)

export type UseTenantReactivateProps = Omit<
  UseMutateProps<void, ProblemDetails, void, void, TenantReactivatePathParams>,
  'path' | 'verb'
> &
  TenantReactivatePathParams

export const useTenantReactivate = ({ tenantCode, ...props }: UseTenantReactivateProps) =>
  useMutate<void, ProblemDetails, void, void, TenantReactivatePathParams>(
    'PUT',
    (paramsInPath: TenantReactivatePathParams) =>
      `/activitymonitoring/api/v1/tenant/${paramsInPath.tenantCode}/reactivate`,
    { pathParams: { tenantCode }, ...props }
  )

export type TenantGetAllProps = Omit<
  GetProps<ActivityMonitoringTenantDto[], ProblemDetails, void, void>,
  'path'
>

export const TenantGetAll = (props: TenantGetAllProps) => (
  <Get<ActivityMonitoringTenantDto[], ProblemDetails, void, void>
    path={`/activitymonitoring/api/v1/tenant/all`}
    {...props}
  />
)

export type UseTenantGetAllProps = Omit<
  UseGetProps<ActivityMonitoringTenantDto[], ProblemDetails, void, void>,
  'path'
>

export const useTenantGetAll = (props: UseTenantGetAllProps) =>
  useGet<ActivityMonitoringTenantDto[], ProblemDetails, void, void>(
    `/activitymonitoring/api/v1/tenant/all`,
    props
  )

export type TimeWindowGetDefaultProps = Omit<
  GetProps<TimeWindowDto, ProblemDetails, void, void>,
  'path'
>

export const TimeWindowGetDefault = (props: TimeWindowGetDefaultProps) => (
  <Get<TimeWindowDto, ProblemDetails, void, void>
    path={`/activitymonitoring/api/v1/timewindow/default`}
    {...props}
  />
)

export type UseTimeWindowGetDefaultProps = Omit<
  UseGetProps<TimeWindowDto, ProblemDetails, void, void>,
  'path'
>

export const useTimeWindowGetDefault = (props: UseTimeWindowGetDefaultProps) =>
  useGet<TimeWindowDto, ProblemDetails, void, void>(
    `/activitymonitoring/api/v1/timewindow/default`,
    props
  )

export type TimeWindowSetDefaultProps = Omit<
  MutateProps<void, ProblemDetails, void, TimeWindowDto, void>,
  'path' | 'verb'
>

export const TimeWindowSetDefault = (props: TimeWindowSetDefaultProps) => (
  <Mutate<void, ProblemDetails, void, TimeWindowDto, void>
    verb="PUT"
    path={`/activitymonitoring/api/v1/timewindow/default`}
    {...props}
  />
)

export type UseTimeWindowSetDefaultProps = Omit<
  UseMutateProps<void, ProblemDetails, void, TimeWindowDto, void>,
  'path' | 'verb'
>

export const useTimeWindowSetDefault = (props: UseTimeWindowSetDefaultProps) =>
  useMutate<void, ProblemDetails, void, TimeWindowDto, void>(
    'PUT',
    `/activitymonitoring/api/v1/timewindow/default`,
    props
  )

export interface TimeWindowGetOverridePathParams {
  personId: string
}

export type TimeWindowGetOverrideProps = Omit<
  GetProps<TimeWindowDto, ProblemDetails, void, TimeWindowGetOverridePathParams>,
  'path'
> &
  TimeWindowGetOverridePathParams

export const TimeWindowGetOverride = ({ personId, ...props }: TimeWindowGetOverrideProps) => (
  <Get<TimeWindowDto, ProblemDetails, void, TimeWindowGetOverridePathParams>
    path={`/activitymonitoring/api/v1/timewindow/override/${personId}`}
    {...props}
  />
)

export type UseTimeWindowGetOverrideProps = Omit<
  UseGetProps<TimeWindowDto, ProblemDetails, void, TimeWindowGetOverridePathParams>,
  'path'
> &
  TimeWindowGetOverridePathParams

export const useTimeWindowGetOverride = ({ personId, ...props }: UseTimeWindowGetOverrideProps) =>
  useGet<TimeWindowDto, ProblemDetails, void, TimeWindowGetOverridePathParams>(
    (paramsInPath: TimeWindowGetOverridePathParams) =>
      `/activitymonitoring/api/v1/timewindow/override/${paramsInPath.personId}`,
    { pathParams: { personId }, ...props }
  )

export interface TimeWindowSetOverridePathParams {
  personId: string
}

export type TimeWindowSetOverrideProps = Omit<
  MutateProps<void, ProblemDetails, void, TimeWindowDto, TimeWindowSetOverridePathParams>,
  'path' | 'verb'
> &
  TimeWindowSetOverridePathParams

export const TimeWindowSetOverride = ({ personId, ...props }: TimeWindowSetOverrideProps) => (
  <Mutate<void, ProblemDetails, void, TimeWindowDto, TimeWindowSetOverridePathParams>
    verb="PUT"
    path={`/activitymonitoring/api/v1/timewindow/override/${personId}`}
    {...props}
  />
)

export type UseTimeWindowSetOverrideProps = Omit<
  UseMutateProps<void, ProblemDetails, void, TimeWindowDto, TimeWindowSetOverridePathParams>,
  'path' | 'verb'
> &
  TimeWindowSetOverridePathParams

export const useTimeWindowSetOverride = ({ personId, ...props }: UseTimeWindowSetOverrideProps) =>
  useMutate<void, ProblemDetails, void, TimeWindowDto, TimeWindowSetOverridePathParams>(
    'PUT',
    (paramsInPath: TimeWindowSetOverridePathParams) =>
      `/activitymonitoring/api/v1/timewindow/override/${paramsInPath.personId}`,
    { pathParams: { personId }, ...props }
  )

export type TimeWindowRemoveOverrideProps = Omit<
  MutateProps<void, ProblemDetails, void, string, void>,
  'path' | 'verb'
>

export const TimeWindowRemoveOverride = (props: TimeWindowRemoveOverrideProps) => (
  <Mutate<void, ProblemDetails, void, string, void>
    verb="DELETE"
    path={`/activitymonitoring/api/v1/timewindow/override`}
    {...props}
  />
)

export type UseTimeWindowRemoveOverrideProps = Omit<
  UseMutateProps<void, ProblemDetails, void, string, void>,
  'path' | 'verb'
>

export const useTimeWindowRemoveOverride = (props: UseTimeWindowRemoveOverrideProps) =>
  useMutate<void, ProblemDetails, void, string, void>(
    'DELETE',
    `/activitymonitoring/api/v1/timewindow/override`,
    { ...props }
  )
