import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { CheckInStatus } from 'generated/api'
import { useStyles } from './Dot.styles'

interface DotProps {
  type: CheckInStatus
}

const Dot = ({ type }: DotProps) => {
  const classes = useStyles({ type })
  const [shouldAnimate, setShouldAnimate] = useState(false)

  useEffect(() => {
    setShouldAnimate(true)
  }, [setShouldAnimate])

  return <div className={clsx(classes.dot, classes.type, { [classes.animate]: shouldAnimate })} />
}
export default Dot
