import React, { useCallback, useEffect, useState } from 'react'
import { NavigationBackIcon } from 'tunstall-ui/icons'
import { isToday, format, subDays, addDays } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes/routes'
import { Button } from 'tunstall-ui/components'
import { createGenerateClassName, StylesProvider, ThemeProvider } from '@material-ui/styles'
import DateFnsUtils from '@date-io/date-fns'
import locale from 'date-fns/locale/en-US'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useStyles, materialTheme } from './SimpleDatePicker.styles'

const generateClassName = createGenerateClassName({
  seed: 'datepicker',
})

interface Props {
  selectedDate: Date
  onDateChange: (value: any) => void
}

// We are using @material-ui/pickers <DatePicker /> for now. We will import it from tunstall-ui once its properly added to it.

const SimpleDatePicker = ({ selectedDate, onDateChange }: Props) => {
  const classes = useStyles()
  const history = useHistory()
  const [buttonRef, setButtonRef] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  if (locale?.options) {
    locale.options.weekStartsOn = 1
  }

  const selectNextDay = useCallback(() => {
    const nextDate = addDays(selectedDate, 1)
    if (!isToday(selectedDate)) {
      onDateChange(nextDate)
    }
  }, [selectedDate, onDateChange])

  const selectPreviousDay = useCallback(() => {
    const previousDate = subDays(selectedDate, 1)
    onDateChange(previousDate)
  }, [selectedDate, onDateChange])

  useEffect(() => {
    const newRoute = routes.history.replace(':date', format(selectedDate, 'yyyy-MM-dd'))
    history.push(newRoute)
  }, [selectedDate, history])

  return (
    <StylesProvider generateClassName={generateClassName}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
        <ThemeProvider theme={materialTheme}>
          <div className={classes.root}>
            <NavigationBackIcon
              data-testid="left-arrow"
              className={classes.leftArrow}
              width={16}
              height={16}
              onClick={selectPreviousDay}
            />
            <Button variant="text" onClick={() => setIsOpen(true)} ref={setButtonRef}>
              {isToday(selectedDate) ? 'Today' : format(selectedDate, 'yyyy-MM-dd')}
            </Button>
            <DatePicker
              PopoverProps={{ anchorEl: buttonRef }}
              disableFuture
              disableToolbar
              variant="inline"
              open={isOpen}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
              TextFieldComponent={() => null}
              value={selectedDate}
              onChange={(date) => onDateChange(date)}
            />
            <NavigationBackIcon
              data-testid="right-arrow"
              className={classes.rightArrow}
              width={16}
              height={16}
              onClick={selectNextDay}
            />
          </div>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  )
}

export default SimpleDatePicker
