import React, { useEffect } from 'react'
import { envVars, urls } from 'config/constants'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { Toolbar } from '@material-ui/core'
import { CssBaseline } from 'tunstall-ui/core'
import { GlobalCSS } from 'tunstall-ui/components'
import { useStyles } from 'App.styles'
import Router from 'routes/Router'
import { RestfulProvider } from 'restful-react'
import ContextProviders from 'contexts/ContextProviders'
import { getCookie } from 'utils/getCookie'

const authToken = getCookie('Bearer')

const App = () => {
  const classes = useStyles()

  // Validate env variables
  // throws error if there are missing variables
  useEffect(() => {
    const missingConstants = Object.entries(envVars).filter(([, val]) => val === undefined)

    if (missingConstants.length > 0) {
      throw new Error(`Missing constants ${missingConstants.join(', ')}`)
    }
  }, [])

  const shouldDisplayApp = authToken || envVars.IGNORE_TOKEN_REQUIREMENT

  return (
    <RestfulProvider
      base={urls.activityMonitoringServiceUrl}
      requestOptions={() => ({
        headers: { Authorization: `Bearer ${authToken}` },
      })}
    >
      {shouldDisplayApp && (
        <ContextProviders>
          <div className={classes.root}>
            <CssBaseline />
            <GlobalCSS />
            <main className={classes.content}>
              <Toolbar />
              <ErrorBoundary>
                <Router />
              </ErrorBoundary>
            </main>
          </div>
        </ContextProviders>
      )}
    </RestfulProvider>
  )
}

export default App
