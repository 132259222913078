import { isPast, isValid } from 'date-fns/esm'
import { useMemo } from 'react'
import { useParams } from 'react-router'

const useGetInitialDate = (): Date => {
  const { date: pathDate } = useParams<{ date: string }>()

  const initialDate = useMemo(() => {
    const dateInPath = new Date(pathDate)
    const date = isValid(dateInPath) && isPast(dateInPath!) ? dateInPath : new Date()
    return date
  }, [pathDate])

  return initialDate
}

export default useGetInitialDate
