import React from 'react'
import { SnackbarProviderWrapper } from './SnackbarContext'
import { GlobalStateContextProvider } from './GlobalStateContext'

interface ContextProvidersProps {
  children?: React.ReactNode
}

const ContextProviders = ({ children }: ContextProvidersProps) => (
  <SnackbarProviderWrapper>
    <GlobalStateContextProvider>{children}</GlobalStateContextProvider>
  </SnackbarProviderWrapper>
)

export default ContextProviders
