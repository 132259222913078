import makeStyles from '@material-ui/styles/makeStyles'
import { spacing, colors } from 'tunstall-ui/core/variables'

export const useStyles = makeStyles(() => ({
  root: {
    minHeight: '164px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: spacing(8),
    marginLeft: '56px',
  },

  title: {
    display: 'flex',
  },

  statusRow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: '100px',
  },

  peopleCount: {
    fontSize: '20px',
    margin: '4px 8px 5px',
    letterSpacing: '-0.2px',
    color: colors.battleshipGreyS20,
    fontWeight: 600,
  },

  statusWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
    paddingTop: spacing(4),
    paddingBottom: spacing(4),
  },

  status: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingRight: spacing(8),
  },

  right: {
    marginRight: spacing(5),
    paddingBottom: spacing(4),
  },

  icon: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    borderRadius: '50%',

    '&.success': {
      backgroundColor: colors.accGreen,
    },
    '&.fail': {
      backgroundColor: colors.accCoral,
    },
    '&.warning': {
      backgroundColor: colors.battleshipGreyT5,
    },
  },

  times: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
  },

  timeTypes: {
    marginRight: spacing(4),
    height: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  timeWindows: {
    marginLeft: spacing(4),
    display: 'flex',
    height: '60px',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  infoIcon: {
    marginLeft: spacing(2),
  },

  averageTimeInfo: {
    display: 'flex',
    alignItems: 'center',
  },

  timeButton: {
    alignSelf: 'flex-start',
  },
}))
