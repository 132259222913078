import makeStyles from '@material-ui/styles/makeStyles'
import { spacing } from 'tunstall-ui/core/variables'

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100%',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: `${spacing(10)} ${spacing(6)}`,
  },
}))
