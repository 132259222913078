import makeStyles from '@material-ui/styles/makeStyles'
import { colors } from 'tunstall-ui/core/variables'
import { CheckInStatus } from 'generated/api'

interface StyleProps {
  type: CheckInStatus
}

const typeToColorMap = {
  None: 'transparent',
  NotYetCheckedIn: 'transparent',
  MissedCheckedIn: colors.accCoral,
  CheckedIn: colors.accGreenS10,
  IsAway: colors.accPumpkin,
  ServiceUnavailable: colors.battleshipGrey,
}

export const useStyles = makeStyles<unknown, StyleProps>(() => ({
  dot: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    borderRadius: '50%',
    width: '8px',
    height: '8px',
    transition: 'transform 0.4s 0.2s ease-in-out',
    backgroundColor: ({ type }) => typeToColorMap[type],
  },

  animate: {
    transform: ({ type }) => {
      let transform = 'translateY(-50%)'

      if (type === 'CheckedIn') {
        transform = 'translateY(calc(-50% - 20px))'
      }
      if (type === 'MissedCheckedIn') {
        transform = 'translateY(calc(-50% + 20px))'
      }

      return transform
    },
  },
}))
