import { getCookie } from 'utils/getCookie'

export const envVars = {
  IGNORE_TOKEN_REQUIREMENT: process.env.REACT_APP_IGNORE_TOKEN_REQUIREMENT ?? false,
}

export const urls = {
  tesWebUrl: getCookie('TesWebUrl') ?? '',
  activityMonitoringServiceUrl: getCookie('ActivityMonitoringServiceUrl') ?? '',
}
