import makeStyles from '@material-ui/styles/makeStyles'
import { spacing } from 'tunstall-ui/core/variables'

export const useStyles = makeStyles(() => ({
  text: {
    marginBottom: spacing(6),
  },

  modalButtons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },

  resetButton: {
    marginRight: 'auto',
  },
}))
1
