import React, { useEffect } from 'react'

interface PrivateRouteProps {
  children?: React.ReactNode
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  // TODO: replace with logic to determine if user is authenticated
  const isAuthenticated = true

  useEffect(() => {
    if (!isAuthenticated) {
      // TODO: redirect to login
    }
  }, [isAuthenticated])

  return isAuthenticated ? <>{children}</> : <></>
}

export default PrivateRoute
