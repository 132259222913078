import makeStyles from '@material-ui/styles/makeStyles'
import { spacing } from 'tunstall-ui/core/variables'

export const useStyles = makeStyles(() => ({
  card: {
    height: '100%',
    '& tr': {
      transition: 'background-color 750ms ease-out',
    },
  },
  timeSpan: {
    display: 'inline-flex',
  },
  timeSpanIcon: {
    marginLeft: spacing(2),
  },
  cardTitle: {
    position: 'relative',
  },
  datePickerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  title: {
    margin: `${spacing(4)} 0 ${spacing(3)} ${spacing(7)}`,
    fontFamily: 'Work Sans',
    fontSize: '18px',
    fontWeight: 500,
    letterSpacing: '-0.81px',
    color: '#1a1a1a',
    lineHeight: 'normal',
  },
}))
